.mentionWrapper {
  width: 100%;
  background: transparent;
  font-size: 0.9rem;
  color: #a9b5c4;
 }
 .mentionWrapper .mentionWrapper__control {
   border: transparent;
   min-height: 45px;
  }

 .mentionWrapper .mentionWrapper__control .mentionWrapper__highlighter {
  padding: 0.7rem 1rem;
 }

 .mentionWrapper .mentionWrapper__control .mentionWrapper__input {
  padding: 0.75rem 1.05rem;
  outline: 0;
  border: 0;
  resize: none;
  outline: none;
  font-size: 0.9rem;
  overflow: hidden;
  color: #727177;
 }

.mentionWrapper .mentionWrapper__control .mentionWrapper__input::placeholder {
  color: #727177;
 }

.mentionWrapper__suggestions {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  color: #000107;
 }

.mentionWrapper__suggestions .mentionWrapper__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
 }

.mentionWrapper
.mentionWrapper__suggestions
.mentionWrapper__suggestions__item--focused {
 color: #eceef3;
 background-color: #5d78f0;
 border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentionedFriend {
  color: #2882f8;
  position: relative;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: none;
  pointer-events: none;
  z-index: 1;
 }