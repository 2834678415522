.profile-letter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #172b4d;
    color: white;
    font-size: 100px;
    width: 180px;
    height: 180px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -30%);
  }

  .profile-letter-sm {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #172b4d;
    color: white;
    font-size: 18px;
    width: 36px;
    height: 36px;
    /* position: absolute;
    left: 50%;
    transform: translate(-50%, -30%); */
  }


  .profile-letter-sm-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #11cdef;
    color: white;
    font-size: 18px;
    width: 36px;
    height: 36px;
    /* position: absolute;
    left: 50%;
    transform: translate(-50%, -30%); */
  }

.profile-letter-lg-primary {
  font-size: 100px;
}

.profile-letter-lg-primary-coaches {
  font-size: 60px;
  background-color: #adb5bd;
}

.profile-picture{
    transition: all 0.15s ease;    
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    width: 180px;
    height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 100%;
}
.card-profile-div{
    width: 180px;
    height: 180px;
    background-color: white;
    transform: translate(-50%, -30%);
    left: 50%;
    position: absolute;
    border-radius: 100%;
}

.card-profile-div:hover{
    transform: translate(-50%, -33%);
}


.profile-picture-sm{
  transition: all 0.15s ease;    
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  width: 36px;
  height: 36px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 100%;
}
.profile-picture-md{
  width: 48px;
  height: 48px;
}

.profile-picture-lg{
  width: 180px;
  height: 180px;
}

.profile-picture-lg-coaches{
  width: 120px;
  height: 120px;
}

.card-profile-div-sm{
  width: 36px;
  height: 36px;
  background-color: white;

  border-radius: 100%;
}

.card-profile-div-md{
  width: 48px;
  height: 48px;
}

.card-profile-div-lg{
  width: 180px;
  height: 180px;
}

.card-profile-div-lg-coaches{
  width: 120px;
  height: 120px;
}

.profile-letter-all-users {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #172b4d;
  color: white;
  font-size: 20px;
  width: 48px;
  height: 48px;
  /* position: absolute;
  left: 50%;
  transform: translate(-50%, -30%); */
}

.custom-height-cell{
  min-height: 70px;
  height: 70px;
}

.custom-height-cell div{
  padding: 0;
  height: 100%;
}

.custom-height-cell div span{
  margin-bottom: 5px;
}

.custom-height-cell-disabled div{
  pointer-events: none;
}


